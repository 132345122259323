
import { defineComponent, ref, PropType } from 'vue';

export enum FilterType {
  SELECTOR = 'selector',
  CHECKBOX = 'checkbox'
}

export interface FilterOption {
  label: string
  type: FilterType
  placeholder?: string
  options?: Array<{
    label: string
    value: string
  }>
}

export type FilterEvent = string[];

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<FilterOption[]>,
      default: null
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  emits: ['change'],
  setup(_, { emit }) {
    const visible = ref(false);
    const values = ref([]);

    const closePopover = () => {
      visible.value = false;
    };

    const apply = () => {
      // emit data to parent
      console.log(values.value);
      emit('change', values.value);
      // close popover
      closePopover();
    };

    return {
      visible,
      values,
      closePopover,
      apply,
      FilterType
    };
  }

});
