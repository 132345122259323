export enum CommentStatus {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export interface Comment {
  id: number
  username: string
  video: string
  message: string
  likes_count: number
  status: CommentStatus
  created_at: string
}

export type PartialComment = Partial<Comment>;
